import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsultancyType } from 'src/app/model/consultancyType';
import { StandarConsultancy } from 'src/app/model/standarConsultancy';
import { ConsultancyTypeService } from 'src/app/service/consultancyType.service';

export interface DialogDataConsultancyStandar {
  standar: StandarConsultancy;
  isEdit: boolean;
}

@Component({
  selector: 'app-quote-edit-dialog-standar',
  templateUrl: './quote-edit-dialog-standar.component.html'
})
export class QuoteEditDialogStandarComponent implements OnInit {

  tituloDialog: string;
  consultancyTypes: ConsultancyType[];

  constructor(
    private consultancyTypeService: ConsultancyTypeService,
    private dialogRef: MatDialogRef<QuoteEditDialogStandarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataConsultancyStandar
  ) { }

  ngOnInit(): void {
    this.consultancyTypeService.getStandarType().subscribe((types: ConsultancyType[]) => this.consultancyTypes = types);
  }

  onOkClick(): void {
    this.dialogRef.close(this.data.standar);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
