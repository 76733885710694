<mat-card>
    <div fxLayout="column">
        <div fxLayout="row" class="flexwrap" *ngIf="!quote.journal">
            <div fxFlex="30" fxFlex.lt-md="100%" *ngFor="let workcenter of quote.workcenters">
                <div fxLayout="column" fxFlex.lt-md="100%">
                    <mat-card-content>
                        <div class="economic">
                            <div fxLayout="row" class="flexwrap">

                                <div fxFlex="100" fxFlex.lt-md="100%">
                                    <div fxLayout="column" fxFlex.lt-md="100%" >
                                        <div fxLayout="column">
                                            <div fxLayout="row" class="flexwrap">
                                                <b>{{workcenter.name}}</b>
                                            </div>
                                          
                                            <div fxLayout="column" style="margin-left: 10px;" *ngIf = "!quote.journal">
                                                <span *ngIf="workcenter.totalAmountDiets > 0" style="font-weight: 600;">Dietas:</span>
                                                <br *ngIf="workcenter.areas?.length > 0">
                                                <div fxFlex="100" fxLayout="column" *ngIf=" workcenter.totalAmountDiets > 0">
                                                    <div fxFlex="100">
                                                        <span>- Dietas: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Precio dietas' }}: {{ workcenter.totalAmountDiets |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste dietas' }}: {{ workcenter.totalCostDiets |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <br>
                                                </div>
                                                <div fxFlex="100" fxLayout="column" *ngIf=" workcenter.totalAmountAudits > 0">
                                                    <div fxFlex="100">
                                                        <span>- Auditorias: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta auditorías' }}: {{ workcenter.totalAmountAudits |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <br>
                                                </div>
                                                <div fxFlex="100" fxLayout="column" *ngIf=" workcenter.totalAmountCourses > 0">
                                                    <div fxFlex="100">
                                                        <span>- Formaciones: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta auditorías' }}: {{ workcenter.totalAmountCourses |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <br>
                                                </div>
                                                <div fxFlex="100" fxLayout="column" *ngIf=" workcenter.totalAmountQualis > 0">
                                                    <div fxFlex="100">
                                                        <span>- cualificaciones: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta cualificaciones' }}: {{ workcenter.totalAmountQualis |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste cualificaciones' }}: {{ workcenter.totalCostQualis |
                                                        currency:'EUR'}}
                                                    </div>
        
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Horas cualificaciones' }}: {{ workcenter.totalTimeQualis }}h
                                                    </div>
                                                    <br>
                                                </div>
                                                <div fxFlex="100" fxLayout="column" *ngIf="quote.totalTimeAir && workcenter.totalTimeAir > 0">
                                                    <div fxFlex="100">
                                                        <span style="font-weight: 600;">- Aire: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta aire' }}: {{ workcenter.totalAmountAir |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste aire' }}: {{ workcenter.totalCostAir |
                                                        currency:'EUR'}}
                                                    </div>
        
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Horas aire' }}: {{ workcenter.totalTimeAir }}h
                                                    </div>
                                                </div>
                                                <br *ngIf="isThermal">
                                                <div fxFlex="100" fxLayout="column" *ngIf="workcenter.totalTimeThermal > 0">
                                                    <div fxFlex="100">
                                                        <span>- Termicos: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta térmicos' }}: {{ workcenter.totalSaleThermal |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste térmicos' }}: {{ workcenter.totalCostThermal |
                                                        currency:'EUR'}}
                                                    </div>
        
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Horas térmicos' }}: {{ workcenter.totalTimeThermal }}h
                                                    </div>
                                                </div>
                                                <br *ngIf="isCalibration">
                                                 <div fxFlex="100" fxLayout="column" *ngIf=" workcenter.totalTimeCalibration > 0">
                                                    <div fxFlex="100">
                                                        <span>- Calibraciones: </span>
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Venta calibraciones' }}: {{ workcenter.totalAmountCalibration |
                                                        currency:'EUR'}}
                                                    </div>
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Coste calibraciones' }}: {{ workcenter.totalCostCalibration |
                                                        currency:'EUR'}}
                                                    </div>
        
                                                    <div fxLayout="row" class="flexwrap">
                                                        {{ 'Horas calibraciones' }}: {{ workcenter.totalTimeCalibration }}h
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br *ngIf="quote.idType === 5 || isConsultancy && !quote.journal">
                                        <div *ngIf="quote.idType === 5 || isConsultancy && !quote.journal" fxLayout="column">
                                            <br *ngIf="isThermal && isConsultancy">
                                            <div fxFlex="100" fxLayout="column" *ngIf="workcenter.totalTimeQualificationThermal > 0">
                                                <div>
                                                    <span>- Termicos: </span>
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Venta: {{workcenter.totalAmountThermal | currency:'EUR'}} 
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Coste: {{workcenter.totalCostThermal | currency:'EUR'}} 
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Tiempo total: {{workcenter.totalTimeThermal}}h
                                                </div>
                                            </div>
                                            <br *ngIf="hasProfile > 0 && isConsultancy">
                                            <div fxFlex="100" fxLayout="column" *ngIf="hasProfile > 0 && isConsultancy">
                                                <div>
                                                    <span>- Perfiles: </span>
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Venta: {{workcenter.totalAmountConsultancy | currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Coste: {{workcenter.totalCostConsultancy| currency:'EUR'}}
                                                </div>
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Tiempo total: {{workcenter.totalTimeConsultancy}}h
                                                </div>
                                            </div>
                                            <br *ngIf="totalCourses > 0 && isConsultancy">
                                            <!--<div fxLayout="row" class="flexwrap" *ngIf="isConsultancy && getCoursesTotal(result.id) > 0">
                                                {{ 'Formación' | translate }}: {{ getCoursesTotal(result.id) | currency:'EUR'}}
                                            </div> -->
                                           <!-- <div fxLayout="row" class="flexwrap" *ngIf="isConsultancy && result.sumAudits > 0">
                                                {{ 'Auditorias' | translate }}: {{ result.sumAudits | currency:'EUR'}}
                                            </div> -->
                                          <!--  <br *ngIf="result.auditDTOs?.length > 0">
                                            <div *ngFor="let audit of result.auditDTOs" fxFlex="100" class="flexwrap"
                                                fxFlex.lt-md="100%">
                                                {{ showAuditName(audit.auditType) | translate }}
                                                <div fxFlex="100" fxFlex.lt-md="100%">
                                                    Precio: {{audit.price| currency:'EUR'}}
                                                </div>
                                            </div> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxFlex.lt-md="100%">
            <mat-card-content>
                <div class="economic">
                    <div fxLayout="row" class="flexwrap" fxFlex="100">
                        <mat-card fxFlex="30" fxLayout="column">
                            <div fxFlex="100" fxFlex.lt-md="100%">
                                {{ 'Tiempo total: ' }} {{quote.totalTime}}h
                            </div>
                           
                            <div fxLayout="row" class="flexwrap" fxFlex="100">
                                {{ 'Coste total: ' }} <div #divCost> {{quote.totalCost | currency:'EUR'}}</div>
                            </div>
                            <div fxLayout="row" class="flexwrap" fxFlex="100" style="font-weight: 600;">
                                {{ 'Precio de venta total: ' }} <div #divTotalAmount> {{quote.totalAmount | currency:'EUR'}}</div>
                                <div style="font-weight: 200;" *ngIf="quote.discountIncrease != null" > &nbsp;    Esta oferta lleva un descuento del {{quote.discountIncrease}} %</div>
                            </div>

                            <div fxLayout="row" class="flexwrap" fxFlex="100" *ngIf="!quote?.scope">
                                {{ 'Procentaje de beneficio: ' }} <div #divTotalgainsPercentaje> {{gainsPercentaje}}</div>%
                            </div>
                        </mat-card>
                    </div>
                </div>
            </mat-card-content>
        </div>
    </div>
</mat-card>