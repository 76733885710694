import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConsultancyType } from '../model/consultancyType';

@Injectable()
export class ConsultancyTypeService {


    private courseSubject = new BehaviorSubject<ConsultancyType>(new ConsultancyType());

    constructor(private http: HttpClient) {
    }

    getStandarType(): Observable<any> {
        const url = environment.quotit + '/consultancyStandarType';

        return this.http.get(url);
    }
}

