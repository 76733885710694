<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
    <div mat-dialog-content class="quotit-theme" *ngIf="!data.showQualis">

        <div fxLayout="row" class="flexwrap">
            <!-- Number -->
            <div fxFlex="5" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.gas.gasPoints" name="gasPoints" required
                        placeholder="{{ 'quoteEdit.dialog.gas.number.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.gas.number.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Name -->
            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.gas.name" name="gasName" required
                        placeholder="{{ 'quoteEdit.dialog.gas.name.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.gas.name.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <!-- Location -->
            <div fxFlex="30" fxFlex.lt-md="100%">
                <mat-form-field class="campoFormulario">
                    <input matInput [(ngModel)]="data.gas.location" name="gasLocation" required
                        placeholder="{{ 'quoteEdit.dialog.gas.location.label' | translate }}">
                    <mat-error>
                        {{ 'quoteEdit.dialog.gas.name.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
             <!-- Count Rest -->
             <div fxFlex="15" fxFlex.lt-md="100%" *ngIf="showCountRest()">
                <mat-form-field class="campoFormulario">
                    <mat-label>{{ 'quoteEdit.dialog.equipment.particleCount.label' | translate }}</mat-label>
                    <mat-select name="equnit" required (selectionChange)="onCountCriteriaChangeRest($event)"
                        [(ngModel)]="data.gas.idCountEssayCriteria">
                        <mat-option value="null">--</mat-option>
                        <mat-option *ngFor="let countEssayCriteria of countEssayCriteriasRest"
                            [value]="countEssayCriteria.id">
                            {{'testType.particleCount.' + countEssayCriteria.reference | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{ 'quoteEdit.dialog.gas.count.error' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Pantalla subcontrata -->
    <!-- Outsourced -->
    <mat-toolbar color="secondary" *ngIf="!data.showQualis">
        {{ 'quoteEdit.dialog.equipment.outsoruced.title' | translate }}
        <div fxFlex="5" fxFlex.lt-md="100%">
            <mat-checkbox style="margin-left: 1em;" (change)="setOutsourced($event.checked)"
                [(ngModel)]="data.gas.outsourced" name="gasOutsourced" >
            </mat-checkbox>
        </div>
    </mat-toolbar>
    <div fxLayout="row" class="flexwrap">
        <div fxFlex="90" fxFlex.lt-md="100%" *ngIf="data.gas.outsourced">
            <app-quote-edit-outsources [providerQuote]="data.gas.provider"
                (providerQuoteEmitter)="onProviderQuoteEmitter($event)"></app-quote-edit-outsources>
        </div>
    </div>

    <!-- Pantalla ensayos -->
    <mat-toolbar color="secondary">
        {{ 'quoteEdit.dialog.equipment.essays.title' | translate }}
    </mat-toolbar>
    <app-quote-essay-air  [testsConfig]="testsByGas" (testCheckEmitter)="onCheckTest($event)"></app-quote-essay-air>
    <mat-toolbar color="secondary" >
        {{ 'quoteEdit.dialog.equipment.qualifications.title' | translate }}
    </mat-toolbar>
    <app-quote-consultory-qualifications  [qualificationsConfig]="consultancyTypes"
    (consultoryCheckEmitter)="onCheckQualification($event)" >
    </app-quote-consultory-qualifications>
    <div mat-dialog-actions class="quotit-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>

</form>