<h1 mat-dialog-title>{{tituloDialog}}</h1>
<form>
    <div mat-dialog-content class="air-theme">
        <div fxLayout="row" fxLayoutGap="16px" class="campoFormulario">
            <mat-form-field fxFlex="100">
                <mat-label>{{ 'quoteEdit.general.audit.auditory.title' | translate }}</mat-label>
                <mat-select required name="auditTypes" [(ngModel)]="data.audit.auditType"
                    (selectionChange)="onChangeType(data.audit.auditType)">
                    <mat-option *ngFor="let auditType of auditTypes" [value]="auditType.id">
                        {{ 'quoteEdit.general.audit.' + auditType.translate | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="16px" class="campoFormulario" *ngIf="data.audit.auditType === 1">
            <div fxFlex="5">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ 'quoteEdit.general.audit.produts.title' | translate }}</mat-label>
                    <mat-select required name="auditProducts" [(ngModel)]="data.audit.idProduct"
                        (selectionChange)="findAuditConfig(); addProducts()">
                        <mat-option *ngFor="let product of auditProducts" [value]="product.id">
                            {{ product.id }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="15" *ngFor="let product of data.audit.auditComplements; let indexC = index">
                <div fxLayout="column">
                    <div>
                        <mat-form-field fxFlex="100">
                            <input matInput required type="text" [(ngModel)]="product.name" [name]="'name'+indexC"
                                placeholder="{{ 'quoteEdit.general.audit.name.title' | translate}}">
                            <mat-error>
                                {{ 'quoteEdit.dialog.audit.name.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field fxFlex="100">
                            <input matInput required type="text" [(ngModel)]="product.supplier" [name]="'supplier'+indexC"
                                placeholder="{{ 'quoteEdit.general.audit.supplier.title' | translate}}">
                            <mat-error>
                                {{ 'quoteEdit.dialog.audit.supplier.error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div fxFlex="10" *ngIf="data.audit.idProduct != null">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ 'quoteEdit.general.audit.site.title' | translate }}</mat-label>
                    <mat-select required name="auditSite" [(ngModel)]="data.audit.idAuditSite"
                        (selectionChange)="findAuditConfig()">
                        <mat-option *ngFor="let auditSite of auditSites" [value]="auditSite.id">
                            {{ 'quoteEdit.general.audit.' + auditSite.translate | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
    

            <div fxFlex="10" *ngIf="data.audit.idAuditSite != null && data.audit.idAuditSite === 2">
                <mat-form-field fxFlex="100">
                    <mat-label>{{ 'quoteEdit.general.audit.country.title' | translate }}</mat-label>
                    <mat-select required name="auditCountry" [(ngModel)]="data.audit.idCountry"
                        (selectionChange)="findAuditConfig()">
                        <mat-option *ngFor="let country of countrys" [value]="country.id">
                            {{ 'country.' + country.translate | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxFlex="20" *ngIf="data.audit?.journey != null">
                <mat-label>{{ 'quoteEdit.general.audit.journey.title' | translate }}</mat-label>
                <input matInput name="journey" type="text" value="{{showJourney(data.audit.journey)}} " readonly>
            </div>
            <div fxFlex="20" *ngIf="data.audit?.hours != null">
                <mat-label>{{ 'quoteEdit.general.audit.hours.title' | translate }}</mat-label>
                <input matInput name="hours" type="number" value="{{data.audit.hours}}" readonly>
            </div>
            <div fxFlex="20" *ngIf="data.audit?.price != null">
                <mat-label>{{ 'quoteEdit.general.audit.price.title' | translate }}</mat-label>
                <input matInput name="price" type="number" value="{{data.audit.price}}" readonly>
            </div>
        </div>
        <div *ngIf="data.audit.auditType > 1">
            <div fxLayout="column">
                <div class="container">
                    <div class="row">
                        <div div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxFlex="25" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <mat-label> {{'quoteEdit.general.consultory.profile'|
                                        translate}}
                                    </mat-label>
                                    <mat-select [(ngModel)]="profile.resourceCodeNav"
                                        (selectionChange)="onConsultorytypeChance($event)" name="profileType">
                                        <mat-option
                                        *ngFor="let itemProfile of resources"
                                        [value]="itemProfile.No">
                                        {{itemProfile.Name }}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" fxFlex.lt-md="100%">
                                <mat-form-field class="campoFormulario">
                                    <input matInput type="number" [(ngModel)]="profile.hours" name="hours" min="0"
                                        placeholder="{{ 'quoteEdit.general.consultory.hours' | translate}}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="25" fxFlex.lt-md="100%" *ngIf="showClosedPrice">
                                <mat-form-field class="campoFormulario">
                                    <input matInput required type="number" name="closedPrice"
                                        [(ngModel)]="profile.price" min="0"
                                        placeholder="{{ 'quoteEdit.general.consultory.closedprice' | translate}}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10" fxFlex.lt-md="100%" div fxLayout="row" fxLayoutAlign="start center">
                                <mat-checkbox matTooltip="{{ 'quoteEdit.general.button.implant' | translate }}"
                                    name="quoteImplant" [(ngModel)]="profile.implant">
                                    {{ 'quoteEdit.general.button.implant' | translate }}
                                </mat-checkbox>
                            </div>
                            <div fxFlex="15">
                                <button mat-raised-button color="accent" (click)="addConsultancyProfile()">
                                    {{'button.add'| translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="100" *ngIf="data.audit?.profiles?.length > 0">
                    <div fxLayout="column">
                        <div div fxFlex="100" fxFlex.lt-md="100%">
                            <div fxFlex="40" fxFlex.lt-md="100%">
                                {{'quoteEdit.general.consultory.profile'| translate}}
                            </div>
                            <div fxFlex="15" fxFlex.lt-md="100%">
                                {{ 'quoteEdit.general.consultory.hours' | translate}}
                            </div>
                            <div fxFlex="15" fxFlex.lt-md="100%">
                                {{ 'quoteEdit.general.consultory.closedprice' | translate}}
                            </div>
                            <div fxFlex="15" fxFlex.lt-md="100%">
                                {{ 'quoteEdit.general.button.implant' | translate }}
                            </div>
                            <div fxFlex="15" fxFlex.lt-md="100%">

                            </div>
                        </div>
                        <div class="row" *ngFor="let profilex of data.audit?.profiles; let profileIndex = index">
                            <div div fxFlex="100" fxFlex.lt-md="100%">
                                <div fxFlex="40" fxFlex.lt-md="100%">
                                    {{ showProfileName(profilex.resourceCodeNav) | translate }}
                                </div>
                                <div fxFlex="15" fxFlex.lt-md="100%">
                                    {{ profilex.hours }}h.
                                </div>
                                <div fxFlex="15" fxFlex.lt-md="100% ">
                                    {{profilex.price | currency:'EUR'}}
                                </div>
                                <div fxFlex="15" fxFlex.lt-md="100%">
                                    <mat-icon style="font-size: 20px;" *ngIf="profilex.implant">check</mat-icon>
                                    <mat-icon style="font-size: 20px;" *ngIf="!profilex.implant">cancel</mat-icon>
                                </div>
                                <div fxFlex="15" fxFlex.lt-md="100%">
                                    <button mat-icon-button color="accent"
                                        (click)="deleteConsultancyProfile(profileIndex)">
                                        <mat-icon style="font-size: 20px;">delete</mat-icon>
                                    </button>
                                </div>
                                <!-- ELIMINAR CONSULTORIA -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="air-theme">
        <div fxFlex></div>
        <button mat-raised-button color="primary" (click)="onOkClick()">{{ 'button.save' | translate }}</button>
        <button mat-raised-button color="secondary" (click)="onNoClick()">{{ 'button.cancel' | translate }}</button>
    </div>
</form>